.widgetWrapper {
  max-width: 960px;
  margin: 0 auto 35px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  border: none;
  .content {
    display: flex;
    &>* {
      flex-basis: 365px;
      flex-grow: 1;
      max-width: 365px;
    }
    .left {
      .headline {
        font-size: 1rem;
      }
      .btnAction {
        width: 100%;
        text-align: center;
      }
      .inputNameSurnameGroup {
        flex-wrap: nowrap;
      }
    }
    .right {
      background: no-repeat center center;
      background-size: cover;
      background-image: url("./assets/signin-cover.jpg"); // fallback
      background-image: linear-gradient(-180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, .25) 100%), url("./assets/signin-cover.jpg");
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 30px;
      * {
        color: white !important;
      }
      .headline {
        font-weight: 500;
        font-size: 1rem;
        i {
          margin-right: 12px;
        }
      }
      .desc {
        font-size: 1rem;
        margin-top: .875rem;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}