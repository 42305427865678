@import '../../vars.scss';

.inputWrapper {
  position: relative;
}

.input {
  border: 1px solid $borderColorDarker;
  border-radius: 4px;
  display: block;
  width: 100%;
  background: white;
  padding: 10px 15px;
  transition: 0.2s all;
  outline: none;

  & + * {
    margin-top: 10px;
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.3);
  }

  &:not(:read-only):focus {
    border-color: $primary;
  }

  &::-webkit-input-placeholder,
  &::-moz-placeholder,
  &:-ms-input-placeholder,
  &:-moz-placeholder {
    color: $textSecondary;
  }

  font-size: 0.875rem;

  &.errorHighlight {
    border-color: $red;
  }
}

.errorMessage {
  color: $red;
  margin-top: 6px;
  line-height: 1.375rem;
}

.iconWrapper {
  position: absolute;
  top: 50%;
  color: $borderColorDarker;
  transform: translateY(-50%);

  &:not(.iconWrapperRight) {
    left: 12px;

    & + input {
      padding-left: 40px;
    }
  }

  &.iconWrapperRight {
    left: auto;
    right: 12px;

    & + input {
      padding-right: 40px;
    }
  }
}

.clear {
  .iconWrapper {
    &:not(.iconWrapperRight) {
      left: 0;

      & + input {
        padding-left: 28px;
      }
    }

    &.iconWrapperRight {
      right: 0;

      & + input {
        padding-right: 28px;
      }
    }
  }

  input {
    padding: 7px 0;
    border: none !important;
    background: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5500s ease-in-out 0s !important;
  color: $textBase !important;
  -webkit-text-fill-color: $textBase !important;
}
