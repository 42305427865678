@import '_vars.scss';
$border: 1px solid rgba(255, 255, 255, 0.2);
.wrapper {
  background-color: $bgDark;
  transition: .2s all .2s;
  overflow: initial;
  z-index: 900;
  position: relative;
  &.open {
    @media screen and (max-width: 768px) {
      transform: translateX(270px);
    }
  }
  &.collapsed {
    width: 70px;
    flex-basis: 70px;
  }
}

.wrapper,
.topBar {
  border: none;
  .title {
    color: white;
    i {
      color: white;
    }
  }
}

.topBar {
  border-bottom: $border;
  background-color: transparent;
}

.content {
  height: auto;
  flex-shrink: 1;
  flex-basis: 100px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  &>* {
    flex-shrink: 0;
  }
  section>span:first-child {
    color: white;
    opacity: .6;
  }
  .fillExpand {
    flex-grow: 1;
    cursor: zoom-in;
  }
  &>section:first-child>span:first-child {
    background: transparent !important;
    margin: 0 !important;
  }
}

.navCount {
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  color: $textMain;
}

.aboutAppContainer {
  flex-basis: 130px;
  flex-shrink: 0;
  padding: 25px;
  border-top: $border;
  .copyright {
    font-size: 0.875rem;
    line-height: 1.375rem;
    margin-bottom: 8px;
    color: white;
  }
  .copyAbout {
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: white;
    opacity: .6;
  }
  .avatarName {
    color: white;
  }
  opacity: 1;
  max-height: 130px;
  transition: .2s opacity .2s,
  .2s max-height .2s,
  .2s padding-top .2s,
  .2s padding-bottom .2s,
  .2s max-height .2s;
  &.collapsed {
    opacity: 0;
    padding-top: 0;
    padding-bottom: 0;
    max-height: 0;
    transition: .2s all,
  }
}

.wrapper {
  @media screen and (max-width: 768px) {
    z-index: 1000;
    position: fixed;
    right: 100%;
  }
}

button.buttonExpandCollapse {
  width: 70px !important;
  height: 70px !important;
}