@import '../../vars.scss';
.horizontal {
  height: 1px;
  width: 100%;
  background: $borderColor;
  &.margin {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.vertical {
  width: 1px;
  height: 100%;
  background: $borderColor;
  &.margin {
    margin-left: 24px;
    margin-right: 24px;
  }
}