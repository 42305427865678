@import '_vars.scss';
$border: 1px solid rgba(255, 255, 255, 0.2);
.appMenuWrapper {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.appSwitchButton {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: none;
  outline: none;
  color: rgba(255, 255, 255, 0.6);
  background: transparent;
  cursor: pointer;
  position: relative;
  &:hover {
    color: white;
  }
  .appNameTitle {
    transition: .2s all;
  }
  .iconWrapper {
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 69px;
    background: rgba(0, 0, 0, 0.1);
  }
  .hoverText {
    position: absolute;
    top: 50%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: .2s;
    transform: translateY(30%);
    font-size: .875rem;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .3px;
    color: white;
  }
  &:not(.collapsed):hover {
    .hoverText {
      transform: translateY(-50%);
      pointer-events: initial;
      opacity: 1;
    }
    .appNameTitle {
      opacity: 0;
      transform: translateY(-50%);
    }
  }
  .titleText,
  .iconWrapper {
    transition: .2s all .4s;
  }
  .logoImg {
    width: 100%;
  }
  .logoIcon {
    transition: .2s all .2s;
  }
  &.collapsed {
    width: 70px;
    overflow: hidden;
    transition: .0s overflow .2s;
    .titleText,
    .iconWrapper,
    .logoIcon {
      transition: .2s all;
    }
    .titleText,
    .iconWrapper {
      opacity: 0;
      pointer-events: none;
    }
    .logoIcon {
      transform: translateX(-6px);
    }
    &:hover {
      .logoIcon {
        opacity: 1;
      }
    }
  }
}

.appMenu {
  position: absolute;
  width: 400px;
  border-radius: 4px;
  background: white;
  top: 14px;
  left: 100%;
  transform: translateX(-6px);
  z-index: 1000;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.35);
  transition: .2s all;
  visibility: hidden;
  opacity: 0;
  &.appMenuOpen {
    visibility: visible;
    opacity: 1;
  }
  &:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid white;
    position: absolute;
    right: 100%;
    top: 12px;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: flex;
  .appItem {
    display: block;
    flex-basis: 50%;
    flex-grow: 0;
    height: 200px;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $textMain;
    border-bottom: $borderDefault;
    &:nth-child(2n + 1) {
      border-right: $borderDefault;
    }
    &:hover {
      color: $primary;
    }
    .icon {
      font-size: 2rem;
      transition: .2s all;
    }
    .title {
      margin-top: 16px;
      font-size: .75rem;
      text-transform: uppercase;
      font-weight: 500;
      text-align: center;
      opacity: .7;
    }
  }
}

.appTitle {
  display: block;
  font-size: 1.125rem;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
}

.appName {
  font-size: .6rem;
  opacity: .7;
  text-transform: uppercase;
  letter-spacing: .2px;
  color: white;
}

.titleText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.logoIcon {
  font-size: 1.5rem;
  color: white;
  opacity: .7;
  margin-right: 14px;
  color: #9EA0A5;
}

.iconButton {
  cursor: pointer;
  border: none;
  outline: none;
  color: white;
}