@import "_vars.scss";
.widgetWrapper {
  max-width: 960px;
  min-width: 500px;
  margin: auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  border: none;
  .content {
    display: flex;
    & > * {
      flex-basis: 365px;
      flex-grow: 1;
      max-width: 365px;
    }
    .left {
      .headline {
        font-size: 1rem;
      }
      .btnAction {
        width: 100%;
        text-align: center;
      }
      .inputNameSurnameGroup {
        flex-wrap: nowrap;
      }
    }
    .right {
      background: no-repeat center center;
      background-size: cover;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 30px;
      * {
        color: white !important;
      }
      .headline {
        font-weight: 500;
        font-size: 1rem;
        i {
          margin-right: 12px;
        }
      }
      .desc {
        font-size: 1rem;
        margin-top: 0.875rem;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.registerWrapper {
  min-height: 100vh;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-height: 700px) {
    // justify-content: center;
  }
  .fixedWidth {
    max-width: 800px;
  }

  .formInputGroup {
    & * {
      // font-family: $fontFamily2;
      // color: $black;
    }
    label {
      margin-bottom: -10px;
      margin-left: 10px;
      display: block;
      z-index: 100;
      position: relative;
      background: white;
      width: fit-content;
      padding: 0 10px;
      text-transform: uppercase;
      font-size: 12px;
      font-family: $fontFamily2;
      color: $black;
    }
    input {
      width: 100%;
      // margin-bottom: 15px;
      padding: 10px;
      border-radius: 2px;
      color: black;
      background-color: #ffffff;
      border: 1px solid lightgray;
      font-family: $fontFamily;
    }
    .checkbox {
      margin: 10px 0;
      padding: 0;
    }
    .inputField {
      // margin-bottom: 15px;
    }
    .groupLabel {
      margin: 20px 0 10px;
      padding: 0;
      font-size: 1rem;
      font-family: $fontFamily2;
      color: $black;
    }
  }
}

.smallLoader {
  min-height: 30px;
}

.yellowBtn {
  background: #ffb500 !important;
  border: none !important;
  border-radius: 0;
  color: black !important;
  &:hover,
  &:active {
    border-radius: 0;
    border: none;
    color: black;
  }
}
.tabHeader {
  .tabTitle,
  .tabDescription {
    color: $black;
  }
}

.tabTitle {
  font-family: $fontFamily2BD;
}
