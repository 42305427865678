.wrapper {
  padding: 30px;
  display: flex;
  flex-direction: column;
  &>*:not(:last-child) {
    margin-bottom: 30px;
  }
  &.limitWidth {
    max-width: 900px;
  }
}