@import '_vars.scss';
.selectButton {
  min-width: 150px;
  width: 100%;
  justify-content: space-between;
  & > div {
    justify-content: space-between !important;
  }
  .buttonIcon {
    padding-left: 10px;
    transform: translateX(10px);
    svg {
      display: block;
    }
  }
}

.placeholder {
  color: $textSecondary;
}

.emptyContainer {
  font-size: 0.875rem;
  text-align: center;
  padding: 30px;
}

.buttonWrapper {
  position: relative;
}

.clearButton {
  height: 20px;
  width: 20px;
  position: absolute;
  border-radius: 50%;
  right: -10px;
  top: calc(50% - 10px);
  z-index: 100;
  outline: none !important;
  padding: 0;
  transition: 0.2s all;
  .clearIcon {
    font-size: 0.6rem;
    transform: rotate(45deg);
    opacity: 0.3;
    transition: 0.2s;
  }
  &:hover {
    border-color: darken($borderColorDarker, 10%);
    .clearIcon {
      opacity: 1;
    }
  }
  &:active {
    background: #eee;
  }
}
