$offset: 5px;
.wrapper {
  position: relative;
}

.block {
  display: block;
}

.menu {
  position: absolute;
  z-index: 500;
  &.bottomLeft {
    top: calc(100% + 2px);
    left: 0;
    right: auto;
  }
  &.bottomRight {
    top: calc(100% + 2px);
    left: auto;
    right: 0;
  }
  &.bottomCenter {
    top: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
  }
  &.topLeft {
    bottom: calc(100% + 2px);
    left: 0;
    right: auto;
  }
  &.topRight {
    bottom: calc(100% + 2px);
    left: auto;
    right: 0;
  }
  &.topCenter {
    bottom: calc(100% + 2px);
    left: 50%;
    transform: translateX(-50%);
  }
}