@import '_vars.scss';

.modalWrapper {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 100000;
  // padding: 20vh 30px;
  padding: 5vh 30px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  h2,
  h3 {
    font-weight: 500;
  }
}

.wrapper {
  padding: 50px;
  background: white;
  border: $borderDefault;
  border-radius: 4px;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
  width: 90vw;
  max-width: 700px;
  position: relative;
  z-index: 100;
}

.outer {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.closeButton {
  position: absolute;
  top: 1rem;
  right: 1rem;

  .closeIcon {
    font-size: 1.5rem;
  }
}