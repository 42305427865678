.pageWrapper {
  min-height: 100vh;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-height: 700px) {
    justify-content: center;
  }
}

.logo {
  width: 250px;
  margin-bottom: 1rem;
}