@import '../../vars.scss';

.wrapper {
  font-size: .875rem;
  color: $textMain;
  display: block;
  padding: 0 30px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon {
    display: inline-block;
    margin-right: 12px;
    width: 18px;
    text-align: center;
    transition: .2s all;
  }

  .rightEl {
    font-size: 0.75rem;
    color: $textSecondary;
    line-height: 1rem;
  }

  .text {
    display: flex;
  }

  .textContent {
    transition: 0s transform .4s, .2s opacity .5s;
    min-width: 150px;
    text-overflow: ellipsis;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
  }

  &.highlighted {
    .text {
      font-weight: 500;
    }
  }

  // active
  &:global(.active) {
    font-weight: 500;
    color: $primary;
    background: rgba(84, 147, 245, .05);
    box-shadow: inset 3px 0 0 0 $primary;
  }

  &:hover {
    color: $primary;
    background: rgba(84, 147, 245, .02);
  }

  &.collapsed {

    .textContent,
    .rightEl {
      visibility: hidden;
      position: absolute;
      transform: translateX(300px);
      opacity: 0;
    }

    &+* {
      margin-top: 5px;
    }
  }

  &.collapsed:hover {
    .textContent {
      position: absolute;
      background: lighten($bgDark, 20%);
      color: white;
      transform: translateX(-5px) translateY(-10px);
      opacity: 1;
      visibility: visible;
      transition: 0s transform 0s, .2s opacity;
      left: 100%;
      padding: 10px 12px;
      border-radius: 4px;
      box-shadow: 0 0 10px 0 rgba(196, 142, 142, 0.3);
    }
  }
}


.wrapper.onDark {
  margin: 0 15px;
  border: none;
  border-radius: 4px;
  padding: 0 15px;
  font-weight: 500;
  color: white;
  box-shadow: none;

  &>* {
    color: white;
  }

  &.collapsed {
    margin: 0 14px;
    padding: 0 12px;
  }

  &:global(.active) {
    background: white;
    box-shadow: none;

    * {
      color: $bgDark;
    }
  }

  &:not(:global(.active)):hover {
    color: white;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    box-shadow: none;
  }
}