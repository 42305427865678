@import '../../vars.scss';
.wrapper {
  width: 100%;
  border-collapse: collapse;
  tr {
    td,
    th {
      text-align: left;
      padding: 15px 15px;
      border-bottom: $borderDefault;
      line-height: 1.5;
      &:first-child {
        padding-left: 30px;
      }
      &:last-child {
        padding-right: 30px;
      }
    }
    th {
      font-weight: 500;
      font-size: .75rem;
      padding: 8px 15px;
      text-transform: uppercase;
      color: $textSecondary;
      line-height: 1.125rem;
      height: 44px;
      align-items: center;
    }
    td {
      font-size: .875rem;
      height: 70px;
      padding: 0 inherit;
      color: $textMain;
    }
  }
}