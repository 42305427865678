@import '_vars.scss';

.table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 4px;
  border-spacing: 0;

  td,
  th {
    padding: 10px 5px;
    text-align: left;
    color: $textSecondary;
    font-weight: 400;
    font-size: 0.875rem;

    &.click {
      cursor: pointer;

      &:hover {
        color: $textPrimary;
      }
    }
  }

  &.large {
    td {
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  th .sortOrderIcon {
    opacity: 0.5;
    margin-left: 6px;
  }

  th:hover {
    .sortOrderIcon {
      opacity: 1;
    }
  }

  th.activeCol {
    color: $primary;
    font-weight: 600;

    .sortOrderIcon {
      opacity: 1;
    }
  }

  // td,
  // th {
  //   &:first-child {
  //     padding-left: 0;
  //   }
  //   &:last-child {
  //     padding-right: 0;
  //   }
  // }
  th {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.6875rem;
    white-space: nowrap;
  }

  tr {
    td {
      border-top: $borderDefault;
      color: $textPrimary;
    }
  }

  &.inContainer {
    tr td:first-child,
    tr th:first-child {
      padding-left: 1rem;
    }

    tr td:last-child,
    tr th:last-child {
      padding-right: 1rem;
    }
  }
}
