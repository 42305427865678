.wrapper {
  background-color: white !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
  & > * {
    min-height: 1px;
  }
}
