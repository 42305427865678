@import '_vars.scss';
@import "./font.scss";


.uikon,
.material-icons {

  @each $name,
  $color in $colorPairs {
    &.#{$name} {
      color: $color;
    }
  }

}

.mr {
  margin-right: .5rem;
}

.ml {
  margin-left: .5rem;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}