@import '_vars.scss';
.container {
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  background: inherit;
  display: inline-flex;
  &:hover {
    .chevronIcon {
      // SVG
      fill: white;
    }
  }
  &:focus {
    outline: none;
  }
}

.content {
  display: flex;
  align-items: center;
}

.name {
  font-size: 14px;
  margin-right: 8px;
  font-weight: 500;
  flex: 1;
  text-align: left;
}

.user {
  // background: $bluey-grey;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}