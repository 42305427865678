@import '../../vars.scss';
.wrapper {
  font-weight: 500;
  font-size: 1rem;
  color: $textMain;
  line-height: 1.75;
  display: flex;
  align-items: center;
  &.large {
    font-size: 1.125rem;
  }
  i {
    margin-right: 14px;
    color: $textSecondary;
  }
}