@import '_vars.scss';
.nav {
  border-right: 3px solid #2ec5f4;
  :global {
    .nav-link.active {
      background: $primary;
      * {
        color: white;
      }
    }
  }
}
