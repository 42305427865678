@import '_vars.scss';

.container {
  max-height: 70vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.searchWrap {
  padding: 10px 30px;
  background: $bg;
  border-bottom: $borderDefault;
  flex-grow: 0;
}

.tableWrapepr {
  overflow: auto;
  flex-grow: 1;

  &>.table>tbody>tr>td {
    padding-left: 30px;
  }
}

.detailTitle {
  font-size: .75rem;
  color: $textMain;
  font-weight: 500;
  width: 0.1%;
  white-space: nowrap;
  vertical-align: top;

}

.hidden {
  display: none;
}


.textExpandButton {
  border: none;
  outline: none;
  font-size: .6rem;
  padding: .15rem .25rem;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}


.table {
  width: 100%;
  font-size: .75rem;
  font-family: 'Roboto Mono', monospace;

  td {
    padding: 7px 10px;
  }

  tr {
    td {
      border-bottom: $borderDefault;
    }

    &:last-child {
      td {
        border-bottom: none;
      }
    }
  }


  .table {
    border: $borderDefault;
  }

  $nbg: $bg;

  .table {
    background: $nbg;

    .table {
      background: white;

      .table {
        background: $nbg;

        .table {
          background: white;

          .table {
            background: $nbg;

            .table {
              background: white;

              .table {
                background: $nbg;

                .table {
                  background: white;

                  .table {
                    background: $nbg;

                    .table {
                      background: white;

                      .table {
                        background: $nbg;

                        .table {
                          background: white;

                          .table {
                            background: $nbg;

                            .table {
                              background: white;

                              .table {
                                background: $nbg;

                                .table {
                                  background: white;

                                  .table {
                                    background: $nbg;

                                    .table {
                                      background: white;

                                      .table {
                                        background: $nbg;

                                        .table {
                                          background: white;

                                          .table {
                                            background: $nbg;

                                            .table {
                                              background: white;

                                              .table {
                                                background: $nbg;

                                                .table {
                                                  background: white;
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}