@import '_vars.scss';
.box {
  max-width: 760px;
  padding: 20px 30px;
  border-radius: 4px;
  &:not(:last-child) {
    margin-top: 30px;
  }
  &+* {
    margin-top: 30px;
  }
  &.success {
    border: 1px solid $green;
    background: rgba($green, .05);
  }
  &.error {
    border: 1px solid $red;
    background: rgba($red, .05);
  }
}