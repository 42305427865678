.wrapper {
  display: flex;
  align-items: center;
  background-color: #f7c065;
  padding: 20px;
  justify-content: center;
  .iconWrapper {
  }
  .description {
    color: white;
  }
  .resendBtn {
    margin-left: 20px;
    margin-top: 0px;
  }
}
