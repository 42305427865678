@import "../../../vars";
.container {
  width: 50%;
  min-width: 100px;
}

.title {
  font-family: $fontFamily2BD;
  font-size: $largeText;
}

.description {
  margin-bottom: 20px;
  font-family: $fontFamily;
}

.Button {
  background-color: $buttonColor;
  margin-top: 30px;
  font-family: $fontFamily2;
}

a {
  color: $buttonColor;
}

.ssoAuth {
  font-family: "Futura Std Book" !important;
  padding: 5px 10px;
  color: black;
  border: none;
  border-radius: 0;
  background: #ffb500;
}
