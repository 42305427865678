@import "_vars.scss";

.selectFlag {
  margin-right: 10px;
  transform: translateX(-3px);
  height: 14px;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.05);
}

.actions {
  height: 38px;

  @media screen and (max-width: 768px) {
    .actionMobileHidden {
      display: none;
    }
  }

  @media screen and (max-width: 400px) {
    display: none;
  }
}

.wrapper {
  position: relative;

  @media screen and (max-width: 400px) {
    justify-content: flex-end;
  }

  .menuButton {
    display: none;
  }

  @media screen and (max-width: 768px) {
    .menuButton {
      display: block;
      position: absolute;
      border-radius: 0;
      border: none !important;
      border-right: $borderDefault !important;
      width: 56px;
      height: 56px;
      left: 0;
      top: 0;
      font-weight: 600;
      box-shadow: none;
    }

    padding-left: 56px;
  }
}

.crumbs {
  display: flex;
  justify-content: flex-start;

  .crumb {
    font-weight: 500;
    font-size: 1.125;

    .arrow {
      color: $textSecondary;
      font-size: 0.7rem;
      padding: 0 8px;
    }

    &:last-child {
      .arrow {
        display: none;
      }
    }
  }
}

.crumb {
  color: $textBase;

  &:last-of-type {
    color: $textMain;
  }

  &:hover {
    color: $primary;
  }
}
.appNameTitle .logo img {
  width: 117px;
}
.bottomline {
  padding-top: 6px;
  padding-left: 20px;
  p {
    font-size: 0.7rem;
    .left {
      font-family: $fontFamily2BD;
    }
    .right {
      font-family: $fontFamily2;
    }
  }
}
