// media
$mediaMobile: "screen and (max-width: 768px)";
$mediaTablet: "screen and (max-width: 1024px)";
$mediaDesktopSmall: "screen and (max-width: 1366px)";

//fonts
@font-face {
  font-family: "HumstSlab712 BT";
  src: url("./fonts/HumanistSlabserif712BT-Roman.eot");
  src: url("./fonts/HumanistSlabserif712BT-Roman.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/HumanistSlabserif712BT-Roman.woff2") format("woff2"),
    url("./fonts/HumanistSlabserif712BT-Roman.woff") format("woff"),
    url("./fonts/HumanistSlabserif712BT-Roman.ttf") format("truetype"),
    url("./fonts/HumanistSlabserif712BT-Roman.svg#HumanistSlabserif712BT-Roman")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HumstSlab712 Blk BT";
  src: url("./fonts/HumanistSlabserif712BT-Black.eot");
  src: url("./fonts/HumanistSlabserif712BT-Black.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/HumanistSlabserif712BT-Black.woff2") format("woff2"),
    url("./fonts/HumanistSlabserif712BT-Black.woff") format("woff"),
    url("./fonts/HumanistSlabserif712BT-Black.ttf") format("truetype"),
    url("./fonts/HumanistSlabserif712BT-Black.svg#HumanistSlabserif712BT-Black")
      format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "EgyptienneURWLig";
  src: url("./fonts/EgyptienneURW-Lig.eot");
  src: url("./fonts/EgyptienneURW-Lig.eot?#iefix") format("embedded-opentype"),
    url("./fonts/EgyptienneURW-Lig.woff2") format("woff2"),
    url("./fonts/EgyptienneURW-Lig.woff") format("woff"),
    url("./fonts/EgyptienneURW-Lig.ttf") format("truetype"),
    url("./fonts/EgyptienneURW-Lig.svg#EgyptienneURW-Lig") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Std Bold";
  src: url("./fonts/FuturaStd-Bold.eot");
  src: url("./fonts/FuturaStd-Bold.eot?#iefix") format("embedded-opentype"),
    url("./fonts/FuturaStd-Bold.woff2") format("woff2"),
    url("./fonts/FuturaStd-Bold.woff") format("woff"),
    url("./fonts/FuturaStd-Bold.ttf") format("truetype"),
    url("./fonts/FuturaStd-Bold.svg#FuturaStd-Bold") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Futura Std Book";
  src: url("./fonts/FuturaStd-Book.eot");
  src: url("./fonts/FuturaStd-Book.eot?#iefix") format("embedded-opentype"),
    url("./fonts/FuturaStd-Book.woff2") format("woff2"),
    url("./fonts/FuturaStd-Book.woff") format("woff"),
    url("./fonts/FuturaStd-Book.ttf") format("truetype"),
    url("./fonts/FuturaStd-Book.svg#FuturaStd-Book") format("svg");
  font-weight: normal;
  font-style: normal;
}
$fontFamily: "HumstSlab712 BT" !important;
$fontFamilyB: "HumstSlab712 Blk BT" !important;
$fontFamily1: "EgyptienneURWLig" !important;
$fontFamily2: "Futura Std Book" !important;
$fontFamily2BD: "Futura Std Bold" !important;
//image
$bgimg: "/assets/images/header_bg.jpg";
// basic
$bg: #fbfbfd;
$bodybg: #4d4d4d;
$bgDark: #252529;
$borderColor: #eaedf3;
$borderColorDarker: #d8dce6;
$borderDefault: 1px solid $borderColor;
$borderColorDark: #2e2e33;
$imgTabColor: #ffb500;
// text
$textMain: #3e3f42;
$textPrimary: $textMain;
$textBase: #6b6c6f;
$textSecondary: #9ea0a5;
$textLight: #cecfd2;
$largeText: 30px;
$buttonColor: #ffb500;
$mainGray: #545857;
$lightGray: #DDDEDD;
$mainBorder: #b1b1b1;
$mainRed: #b83c27;
$largeText: 30px;
$mainBlack: #000000;
$darkGray40Tint: #BBBCBC;
// colors
$green: #38b249;
$blue: #1665d8;
$orange: #f6ab2f;
$violet: #6977ff;
$red: #e6492d;
$o2ablue: #2ec5f4;
$yellow: #facf55;
$azure: #31cac1;
$black: black;

:export {
  green: $green;
  blue: $blue;
  orange: $orange;
  violet: $violet;
  red: $red;
  yellow: $yellow;
  azure: $azure;
}

$success: $green;
$primary: $blue;
$warning: $orange;
$error: $red;

:export {
  green: $green;
  blue: $blue;
  orange: $orange;
  violet: $violet;
  red: $red;
  yellow: $yellow;
  borderColorDarker: $borderColorDarker;
  borderColor: $borderColor;
  bg: $bg;
  bgDark: $bgDark;
  azure: $azure;
  textBase: $textBase;
  textSecondary: $textSecondary;
}

// base color pairs
$colorPairs: (green, #38b249), (blue, #1665d8), (orange, #f6ab2f),
  (violet, #6977ff), (yellow, #facf55), (red, #e6492d);

// mixins
@mixin generateColorVariants($class, $property, $alpha) {
  @each $name, $color in (green, #38b249), (blue, #1665d8), (orange, #f6ab2f),
    (violet, #6977ff), (red, #e6492d)
  {
    #{$class}.#{$name} {
      #{$property}: rgba($color, $alpha);
    }
  }
}

@mixin textOverflow {
  overflow: hidden;
  white-space: nowrap;
}

@mixin backgroundCover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

// others
$boxShadow: 0 1px 3px 0 rgba(0, 0, 0, 0.04);

.data .ant-table-content {
  overflow: auto !important;
  height: 55vh;
}

.data-print .ant-table-content table tbody tr td {
  padding: 3px 0 !important;
}
.data-print .ant-table-content table thead * {
  background: #ffffff;
  padding: 0 !important;
}
.data-print table {
  margin: 10px;
}
