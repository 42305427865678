@import '../../vars.scss';
.wrapper {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 0.75rem;
  color: $textSecondary;
  line-height: 1.5;
  display: block;
  padding: 10px 30px;
  max-height: 50px;
  transition: .1s max-height .3s, .1s padding .3s, .0s background-color .3s, .1s margin-bottom .3s;
  overflow: hidden;
  &.collapsed {
    max-height: 1px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0 30px 0 30px;
    * {
      opacity: 0 !important;
    }
    margin-bottom: 10px;
    transition: .1s max-height,
    .2s padding,
    .1s background-color .1s,
    .2s margin-bottom;
  }
}