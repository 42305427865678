@import "_vars.scss";

.header {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}

.emptyWrapper {
  margin: auto;
  margin-top: 100px;
  max-width: 960px;
  width: 100%;

  .emptyActionName {
    text-transform: uppercase;
  }
}

.type {
  background: $bg;
  border: $borderDefault;
  border-radius: 2px;
  padding: 3px 6px;
  font-size: 0.875rem;
  margin-top: 7px;
  display: inline-block;
  color: $textMain;

  .actionType {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
  }
}

.iconWrapper {
  height: 28px;
  width: 28px;
  border-radius: 4px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 50px;
}

.toggle_loader {
  min-height: 50px;
}
.marginRight {
  margin-right: 5px;
}

.editBtn {
  // color: #3e3f42 !important;
  border: 1px solid #b2b4ae !important;
  border-radius: 0px !important; 
  font-family: 'Futura Std Book' !important;
}

.searchBox {
  display: flex;
  flex-direction: row;
  & > * {
    margin-right: 5px;
    line-height: 15px;
  }
  vertical-align: middle;
}

.pdfBtn {
  margin-right: 10px;
}
.yellowBtn {
  font-family: $fontFamily2;
  background: #ffb500 !important;
  border: none !important;
  border-radius: 0;
  color: black !important;
  &:hover,
  &:active {
    border-radius: 0;
    border: none;
    color: black;
  }
}

.alertMsg {
  max-width: 100%;
  font-family: $fontFamily;
  color: black;
  font-size: 15px;
  padding: 10px 0;
}

.fullname * {
  font-size: 14px;
}

.loadMore {
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  .loadMoreBtn {
    background: $imgTabColor;
    min-width: 250px;
    min-height: 35px;
    padding: 0px 50px;
    border-radius: 0px;
    text-transform: uppercase;
    font-family: "Futura Std Book" !important;
    border: none;
  }
}
