.widgetWrapper {
  max-width: 960px;
  min-width: 500px;
  margin: auto;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  border: none;
  .content {
    display: flex;
    & > * {
      flex-basis: 365px;
      flex-grow: 1;
      max-width: 365px;
    }
    .left {
      .headline {
        font-size: 1rem;
      }
      .btnAction {
        width: 100%;
        text-align: center;
      }
      .inputNameSurnameGroup {
        flex-wrap: nowrap;
      }
    }
    .right {
      background: no-repeat center center;
      background-size: cover;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding: 30px;
      * {
        color: white !important;
      }
      .headline {
        font-weight: 500;
        font-size: 1rem;
        i {
          margin-right: 12px;
        }
      }
      .desc {
        font-size: 1rem;
        margin-top: 0.875rem;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.registerWrapper {
  min-height: 100vh;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("/assets/images/header_bg.jpg");
  background-color: #000000a3;
  background-blend-mode: color;
  background-position-x: -100px;
  background-position-y: -100px;
  @media screen and (min-height: 700px) {
    // justify-content: center;
  }

  .content {
    background: white;
  }

  .btnBox {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-around;
    .btnCancel {
      border: 1px solid #b2b4ae !important;
      border-radius: 0;
      min-width: 160px;
      height: 34px;
      color: black;
      font-family: "Futura Std Book";
      background-image: unset !important;
    }
    .btnSubmit {
      border: 0;
      border-radius: 0;
      background: #ffb500;
      color: #6f452b;
      min-width: 160px;
      font-family: "Futura Std Book" !important;
    }
  }
  .btnLogout {
    background: white;
    border-radius: 0;
    border: 1px solid #a93232 !important;
    color: #a93232;
    font-family: "Futura Std Book" !important;
    &:hover {
      color: #a93232;
    }
  }
  .avatarBox {
    display: flex;
    justify-content: center;
    align-items: center;
    .userAvatar {
      font-family: "Futura Std Bold";
    }
  }

  .inputGroup {
    & > div {
      // display: flex;
      // align-items: baseline;
      // flex-wrap: wrap;
      & div {
        display: inline-block;
      }
    }

    span {
      margin-right: 20px;
      width: 100px;
      text-align: end;
      display: inline-block;
      font-family: "Futura Std Book";
    }
    input {
      min-width: 260px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid gray;
      padding: 0;
      padding-left: 5px;
    }
    p {
      // text-align: center;
      padding-left: 120px;
      max-width: 400px;
    }
  }
  .territory {
    display: flex;
    align-items: baseline;
    flex-direction: row;

    label {
      margin-right: 20px;
      width: 100px;
      text-align: end;
    }
    input {
      min-width: 260px;
      border-radius: 0;
      border: none;
      border-bottom: 1px solid gray;
      padding: 0;
      padding-left: 5px;
    }
  }
}
