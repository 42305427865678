@import '_vars.scss';

.optionPrefix {
  display: inline-block;
  padding: 4px 6px;
  background: $borderColor;
  border-radius: 4px;
  color: $textMain;
  margin-right: 3px;
  font-size: 0.75rem;
  // text-transform: uppercase;
  font-weight: 500;
}
